import styled from 'styled-components'

const Wrapper = styled.footer`
  height: var(--footer-height, 30px); /* Default height for footer */
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  box-shadow: 0 -1px 0px 0px rgba(0, 0, 0, 0.1); /* Subtle top shadow */
  text-align: center;

  p {
    margin: 0;
    font-size: 0.875rem;
    color: var(--grey-500);
  }

  a {
    color: var(--primary-500);
    text-decoration: none;
    transition: color 0.2s ease;
  }

  a:hover {
    color: var(--primary-700);
  }

  @media (min-width: 992px) {
    p {
      font-size: 1rem; /* Slightly larger text for wider screens */
    }
  }
`

export default Wrapper
