import { useAppContext } from '../context/appContext'; // Import the context
import linksData from '../utils/links'; // Import the links data
import { NavLink } from 'react-router-dom';

const NavLinks = ({ toggleSidebar }) => {
  const { user } = useAppContext(); // Get the user from context

  // Filter or modify links based on the user
  const links = linksData.filter((link) => {
    // Only add 'stats' link if the user email matches
    if ((link.text === 'stats' || link.text === 'ajouter utilisateurs' || link.text === 'utilisateurs' ) && user?.email !== 'registreorthotunisie@gmail.com') {
      return false;
    }
    return true;
  });

  return (
    <div className='nav-links'>
      {links.map((link) => {
        const { text, path, id, icon } = link;

        return (
          <NavLink
            to={path}
            key={id}
            onClick={toggleSidebar}
            className={({ isActive }) =>
              isActive ? 'nav-link active' : 'nav-link'
            }
            end
          >
            <span className='icon'>{icon}</span>
            {text}
          </NavLink>
        );
      })}
    </div>
  );
};

export default NavLinks;
