import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Card = ({ isVisible, onClose }) => {
    const navigate = useNavigate();

  if (!isVisible) return null; // Render nothing if the modal is not visible

  return (
    <StyledWrapper>
      <div className="modal-backdrop" onClick={onClose}></div>
      <div className="card">
        <button className="exit-button" onClick={onClose}>
          <svg height="20px" viewBox="0 0 384 512">
            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
          </svg>
        </button>
        <div className="card-content">
          <div className="success-icon">✔</div>
          <p className="card-heading">Accident Ajouté</p>
          <p className="card-description">
            L'accident a été ajouté avec succès à la base de données. 
            Contribuez davantage pour soutenir les études et améliorer les statistiques !
          </p>
        </div>
        <div className="card-button-wrapper">
          <button className="card-button secondary" onClick={() => navigate('/diagnostic')}>
            Aide au Diagnostic pour Fracture de l'Acetabulum
          </button>
          <button className="card-button primary" onClick={() => navigate('/')}>
            Ajouter un autre Accident
          </button>
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 500px;
    background: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    animation: slide-down 0.3s ease;
  }

  .success-icon {
    font-size: 2rem;
    color: #4caf50;
    margin-bottom: 10px;
  }

  .card-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }

  .card-description {
    font-size: 1rem;
    color: #666;
    margin: 10px 0;
  }

  .card-button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .card-button {
    width: 100%;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }

  .primary {
    background-color: #4caf50;
    color: #fff;
  }

  .primary:hover {
    background-color: #45a049;
  }

  .secondary {
    background-color: #e0e0e0;
    color: #333;
  }

  .secondary:hover {
    background-color: #d5d5d5;
  }

  .exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .exit-button svg {
    fill: #888;
    transition: fill 0.2s ease;
  }

  .exit-button:hover svg {
    fill: #000;
  }

  @keyframes slide-down {
    from {
      opacity: 0;
      transform: translate(-50%, -40%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
`;

export default Card;
