import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/appContext';
import { StatsContainer, Loading } from '../../components';

const Stats = () => {
  const { isLoading } = useAppContext();
  const [summaryStats, setSummaryStats] = useState({
    latestAdditionDate: '',
    accessUsersCount: 0,
    totalAccidentsCount: 0,
  });

  useEffect(() => {
    const fetchSummaryStats = async () => {
      try {
        const response = await fetch('/api/v1/accidents/summary-stats', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setSummaryStats({
            latestAdditionDate: data.latestAdditionDate,
            accessUsersCount: data.accessUsersCount,
            totalAccidentsCount: data.totalAccidentsCount,
          });
          console.log('Summary Stats:', data); // Log the summary stats to the console
        } else {
          console.error('Error fetching summary stats');
        }
      } catch (error) {
        console.error('Error fetching summary stats', error);
      }
    };

    fetchSummaryStats();

  }, []); // Empty dependency array ensures this runs once on mount

  if (isLoading) {
    return <Loading center />;
  }

  const handleDownload = async () => {
    try {
      const response = await fetch('/api/v1/accidents/generate-excel', {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'accidents.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        console.error('Error downloading file');
      }
    } catch (error) {
      console.error('Error fetching file', error);
    }
  };

  return (
    <>
      <StatsContainer stats={summaryStats} />
      <div className="form-center">
        <div></div>
        <button 
          style={{ display: 'grid', placeItems: 'center', marginTop: '4rem' }} 
          className='btn btn-block' 
          onClick={handleDownload}
        >
          Télécharger fichier Excel
        </button>
      </div>
    </>
  );
};

export default Stats;
