import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import img from '../assets/images/not-found.svg';
import Wrapper from '../assets/wrappers/ErrorPage';

const Error = () => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowError(true);
    }, 4500); // Delay in milliseconds (e.g., 2000ms = 2 seconds)

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  return (
    <Wrapper className='full-page'>
      {showError && (
        <div>
          <img src={img} alt='not found' />
          <h3>Ohh! Page non trouvée</h3>
          <p>Nous ne parvenons pas à trouver la page que vous recherchez.</p>
          <Link to='/'>Retour à l'accueil</Link>
        </div>
      )}
    </Wrapper>
  );
};

export default Error;
