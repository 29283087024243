import main from '../assets/images/main.svg';
import doctor from '../assets/images/doctor.svg';
import Wrapper from '../assets/wrappers/LandingPage';
import { Logo, Logo2 } from '../components';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useAppContext } from '../context/appContext';
import React from 'react';

const Landing = () => {
  const { user } = useAppContext();
  return (
    <React.Fragment>
      {user && <Navigate to='/' />}
      <Wrapper>
        <nav style={{ marginTop: '20px' }}>
          <Logo />
          <Logo2 />
        </nav>
        <div className='container page'>
          <div className='info'>
            <h1>
              <span>Registre</span> Orthopédique tunisien
            </h1>
            <p>
              Bienvenue sur notre plateforme, méticuleusement conçue pour soutenir les professionnels de santé dans leur 
              mission de gestion des blessures de l'anneau pelvien. En tant que médecins estimés, votre expertise et votre engagement
               jouent un rôle clé dans la fourniture de soins efficaces aux patients.
            </p>
            <Link to='/register' className='btn btn-hero'>
              Connexion
            </Link>
            <div style={{ height: '20px' }}></div>
            <Link to='/demande' className='btn-complementary'>
              Demander un compte
            </Link>
          </div>
          <img src={doctor} alt='docteur' className='img main-img' />
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

export default Landing;
