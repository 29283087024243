import React, { useState, useEffect, useRef } from 'react';
import { FormRow, FormRowSelect, Alert, Card } from '../../components';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage';

const AddAccident = () => {
  const {
    isLoading,
    isEditing,
    showAlert,
    displayAlert,
    displayAlertDateNaissance,
    displayAlertDateAccident,
    accidentType,
    accidentTypeOptions,
    handleChange,
    clearValues,
    createAccident,
    editAccident,
    genre,
    genreOptions,
    provenance,
    provenanceOptions,
    gouvernerat,
    gouverneratOptions,
    dateAccident,
    dateNaissance,
    antecedentOptions,
    antecedents,
    lesionsAssociees,
    lesionOptions,
    traitement,
    traitementOptions,
    userId,
  } = useAppContext();

  const [step, setStep] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSousImage, setSelectedSousImage] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [photoDescriptions, setPhotoDescriptions] = useState([]); 

  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const [isSuccess, setIsSuccess] = useState(false);

  const [isModalVisible, setModalVisible] = useState(false);

  const handlePhotoClick = (photo) => {
      if (selectedPhoto === photo) {
          setSelectedPhoto(null); // Deselect if already selected
      } else {
          setSelectedPhoto(photo); // Select the new photo
      }
  };

  const handleSubmit = (e) => {
    const birthDate = new Date(dateNaissance);
    const today = new Date();
    const accidentDate = new Date(dateAccident);
    e.preventDefault();
    if (step === 1) {
      if (!dateAccident || !dateNaissance || !genre || !accidentType || !provenance || !gouvernerat) {
        displayAlert();
        return;
      }
      if (!dateNaissance || birthDate >= today){
        displayAlertDateNaissance();
        return;
      }
      if (!dateAccident || accidentDate >= today){
        displayAlertDateAccident();
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (antecedents.length === 0 || lesionsAssociees.length === 0) {
        displayAlert();
        return;
      }
      setStep(3);
    } else if (step === 3) {
      if (!selectedImage) {
        displayAlert();
        return;
      }
      setStep(4);
    } else if (step === 4) {
      if (!selectedSousImage) {
        displayAlert();
        return;
      }
      setStep(5);
    }  else if (step === 5) {
      if (selectedPhotos.length > 1) {
        displayAlert();
        return;
      }
      setStep(6);
    } else if (step === 6) {
      if (traitement.length !== 1) {
        displayAlert();
        return;
      }
      setModalVisible(true);

      const accidentData = {
        dateAccident,
        dateNaissance,
        genre,
        accidentType,
        provenance,
        gouvernerat,
        antecedents: antecedents.join(', '),
        lesionsAssociees: lesionsAssociees.join(', '),
        selectedImage,
        selectedSousImage,
        traitement: traitement.join(', '),
        photos: selectedPhotos,
        userId,
      };

      console.log('Submitting accident data:', accidentData);

      if (isEditing) {
        editAccident();
        return;
      }
      createAccident();
      // setIsSuccess(true); 

    }
  };

  const handleDismiss = () => {
    setModalVisible(false);
  };

  const handleAccidentInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ name, value });
  };

  // const handleCheckboxChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   const checked = e.target.checked;

  //   if (checked) {
  //     handleChange({ name, value: [...(name === 'antecedents' ? antecedents : lesionsAssociees), value] });
  //   } else {
  //     handleChange({ name, value: (name === 'antecedents' ? antecedents : lesionsAssociees).filter(item => item !== value) });
  //   }
  // };

  const handleCheckboxChange = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  const checked = e.target.checked;

  if (checked) {
    handleChange({ 
      name, 
      value: [...(name === 'antecedents' ? antecedents : 
                name === 'lesionsAssociees' ? lesionsAssociees : 
                name === 'traitement' ? traitement : []), value] 
    });
  } else {
    handleChange({
      name,
      value: (name === 'antecedents' ? antecedents : 
              name === 'lesionsAssociees' ? lesionsAssociees : 
              name === 'traitement' ? traitement : []
            ).filter(item => item !== value)
    });
  }
};


  const handleImageSelection = (image) => {
    setSelectedImage(image);
    handleChange({ name: 'selectedImage', value: image });

    const imagePhotos = image === 'bassin'
      ? [
          'images/A1.png',
          'images/A2.png',
          'images/A3.png',
          'images/B1.png',
          'images/B2.png',
          'images/B3.png',
          'images/C1.png',
          'images/C2.png',
          'images/C3.png',
          'images/placeholder.png',
          // 'https://media.aofoundation.org/-/jssmedia/surgery/61/61_entry_page_d1.png?h=150',
          // 'https://media.aofoundation.org/-/jssmedia/surgery/61/61_entry_page_d2.png?h=150',
          // 'https://media.aofoundation.org/-/jssmedia/surgery/61/61_entry_page_d3.png?h=150',
          //  'https://via.placeholder.com/150/FFFFFF/FFFFFF?text='

        ]
      : [
          'images/PW.png',
          'images/PC.png',
          'images/AW.png',
          'images/AC.png',
          'images/TR.png',
          'images/PCW.png',
          'images/TPW.png',
          'images/T.png',
          'images/AHT.png',
          'images/ABC.png',
        ];

    const photoDescriptions = image === 'bassin'
      ? [
          'Fracture, Arrachement',
          'Fracture de l`aile iliaque',
          'Fracture transversale du sacrum/coccyx',
          'Rotation externe "Open book"',
          'Rotation interne par compression latérale',
          'Instabilité horizontale bilatérale',
          'Instabilité verticale unilatérale',
          'Instabilité verticale avec instabilité horizontale controlatérale',
          'Instabilité verticale bilatérale',
          // 'Posterior arch repair',
          // 'Anterior arch repair',
          // 'Stable ring injury repair'
        ]
      : [
          'Paroi postérieure',
          'Colonne postérieure',
          'Paroi antérieure',
          'Colonne antérieure',
          'Transverse',
          'Colonne et paroi postérieures',
          'Transversale et paroi postérieure',
          'Fracture en T',
          'Colonne antérieure et hémitransverse postérieur',
          'Bicolonne'
        ];

    setPhotos(imagePhotos);
    setSelectedPhotos([]); // Clear previous selections
    setPhotoDescriptions(photoDescriptions); // Set the descriptions for the selected photos
  };

  const handleSousImageSelection = (image) => {
    setSelectedSousImage(image);
    handleChange({ name: 'selectedSousImage', value: image });


let imagePhotos = [];
let photoDescriptions = [];

// Check for 'AO'
if (image === 'AO') {
  imagePhotos = [
    'images/A1.png',
    'images/A2.png',
    'images/A3.png',
    'images/B1.png',
    'images/B2.png',
    'images/B3.png',
    'images/C1.png',
    'images/C2.png',
    'images/C3.png',
    'images/placeholder.png',
  ];
  photoDescriptions = [
    'Fracture, Arrachement',
    'Fracture de l`aile iliaque',
    'Fracture transversale du sacrum/coccyx',
    'Rotation externe "Open book"',
    'Rotation interne par compression latérale',
    'Instabilité horizontale bilatérale',
    'Instabilité verticale unilatérale',
    'Instabilité verticale avec instabilité horizontale controlatérale',
    'Instabilité verticale bilatérale',
  ];
}

// Check for 'tournelle'
if (image === 'tournelle') {
  imagePhotos = [
    'images/PW.png',
    'images/PC.png',
    'images/AW.png',
    'images/AC.png',
    'images/TR.png',
    'images/PCW.png',
    'images/TPW.png',
    'images/T.png',
    'images/AHT.png',
    'images/ABC.png',
  ];
  photoDescriptions = [
    'Paroi postérieure',
    'Colonne postérieure',
    'Paroi antérieure',
    'Colonne antérieure',
    'Transverse',
    'Colonne et paroi postérieures',
    'Transversale et paroi postérieure',
    'Fracture en T',
    'Colonne antérieure et hémitransverse postérieur',
    'Bicolonne'
  ];
}

// Check for 'fragile'
if (image === 'fragile') {
  imagePhotos = [
    'images/FFP1.png',
    'images/placeholder.png',
    'images/FFP12.png',
    'images/FFP2.png',
    'images/FFP21.png',
    'images/FFP22.png',
    'images/FFP3.png',
    'images/FFP31.png',
    'images/FFP32.png',
    'images/FFP4.png',
    'images/FFP41.png',
    'images/FFP42.png',
    'images/placeholder.png',
  ];
  photoDescriptions = [
    '1-A',
    '',
    '1-B',
    '2-A',
    '2-B',
    '2-C',
    '3-A',
    '3-B',
    '3-C',
    '4-A',
    '4-B',
    '4-C',
  ];
}

// Check for 'autres'
if (image === 'autres') {
  imagePhotos = [
    'images/tricolonne.png',
    'images/FFA.svg',
    'images/question.svg',
    'images/placeholder2.png',
  ];
  photoDescriptions = [
    'Tricolonne',
    'Fracture de Fragilité',
    'Inclassable'
  ];
}

    setPhotos(imagePhotos);
    setSelectedPhotos([]); // Clear previous selections
    setPhotoDescriptions(photoDescriptions); // Set the descriptions for the selected photos
  };

  const togglePhotoSelection = (photo) => {
      const isSelected = selectedPhotos.includes(photo);
      if (isSelected) {
          setSelectedPhotos([]); // Deselect the photo
          handleChange({ name: 'photos', value: [] });
      } else {
          setSelectedPhotos([photo]); // Select only the new photo
          handleChange({ name: 'photos', value: [photo] });
      }
  };

  const btnContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem'
  };

const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
        setIsModalOpen(true); // Show the modal when the page loads

        // Function to close the modal when clicking outside of it
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsModalOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

  return (
    <Wrapper>
      {/* Modal */}
      {isModalOpen && (
          <div className="modal">
              <div className="modal-content" ref={modalRef}>
                  <span className="close" onClick={closeModal}>&times;</span>
                  <div class="banner-container">

                  <img 
                      // src="https://static.euronews.com/articles/stories/07/22/49/88/1200x675_cmsv2_bbd196a6-fb1a-5296-80e1-4e56efeb2f8f-7224988.jpg"
                      src="/images/LogoAfrique.png"
                      alt="Banner Image" 
                      className="banner-img" 
                  />
                  </div>
              </div>
          </div>
      )}
      <style>
        {`
            /* Modal styles */
            .modal {
                position: fixed;
                z-index: 1000;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
            }

            .modal-content {
                background-color: #fff;
                padding: 20px;
                border: 1px solid #888;
                border-radius: 5px;
                width: 80%;
                max-width: 800px;
                position: relative;
            }

            .close {
                color: #C44035;
                position: absolute;
                top: 2px;
                right: 20px;
                font-size: 48px;
                font-weight: bold;
                cursor: pointer;
            }

            .close:hover,
            .close:focus {
                color: black;
                text-decoration: none;
                cursor: pointer;
            }
            .banner-container {
                display: flex;
                justify-content: center;
                align-items: center;   
            }
            .banner-img {
                max-width: 100%;
                height: auto;
            }
        `}
      </style>
      <form className='form'>
        <h3>{isEditing ? 'Edit Accident' : 'Ajouter Accident'}</h3>
        {showAlert && <Alert />}
        <div className='form-center'>
          {step === 1 && (
            <>
              <FormRow
                type="date"
                name="dateAccident"
                labelText={
                  <>
                    Date de l’accident <span style={{ color: 'red' }}>*</span>
                  </>
                }
                value={dateAccident}
                handleChange={handleAccidentInput}
              />
              <FormRow
                type='date'
                name='dateNaissance'
                                labelText={
                  <>
                    Date de naissance <span style={{ color: 'red' }}>*</span>
                  </>
                }
                value={dateNaissance}
                handleChange={handleAccidentInput}
              />
              <FormRowSelect
                name='genre'
                labelText={
                  <>
                    Genre <span style={{ color: 'red' }}>*</span>
                  </>
                }                value={genre}
                handleChange={handleAccidentInput}
                list={genreOptions}
              />
              <FormRowSelect
                name='accidentType'
                labelText={
                  <>
                    Type d’Accident <span style={{ color: 'red' }}>*</span>
                  </>
                }
                value={accidentType}
                handleChange={handleAccidentInput}
                list={accidentTypeOptions}
              />
              <FormRowSelect
                name='provenance'
                labelText={
                  <>
                    Provenance <span style={{ color: 'red' }}>*</span>
                  </>
                }                value={provenance}
                handleChange={handleAccidentInput}
                list={provenanceOptions}
              />
              <FormRowSelect
                name='gouvernerat'
                labelText={
                  <>
                    Gouvernerat <span style={{ color: 'red' }}>*</span>
                  </>
                }                value={gouvernerat}
                handleChange={handleAccidentInput}
                list={gouverneratOptions}
              />
              <div style={{ height: '40px' }}></div>
              {/* <button className='btn btn-block btn-danger' onClick={(e) => { e.preventDefault(); clearValues(); }}>Effacer</button> */}
              <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
            </>
          )}
          {step === 2 && (
            <>
              <div className='form-section'>
                <label style={{ fontWeight: 'bold', fontSize: '18px' }}>Sélectionnez les antécédents et lésions du patient.</label>
                <div className='checkbox-group'>
                  {antecedentOptions.map((option) => (
                      <label ></label>
                  ))}
                </div>
              </div>

              <div className='form-section'>
                <label style={{ fontWeight: 'bold', fontSize: '18px' }}>Antécédents</label>
                <div className='checkbox-group'>
                  {antecedentOptions.map((option) => (
                    <div className='checkbox-item' key={option}>
                      <input
                        type='checkbox'
                        id={option}
                        name='antecedents'
                        value={option}
                        checked={antecedents.includes(option)}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={option}>{option}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className='form-section'>
                <label style={{ fontWeight: 'bold', fontSize: '18px' }}>Lésions Associées</label>
                <div className='checkbox-group'>
                  {lesionOptions.map((option) => (
                    <div className='checkbox-item' key={option}>
                      <input
                        type='checkbox'
                        id={option}
                        name='lesionsAssociees'
                        value={option}
                        checked={lesionsAssociees.includes(option)}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={option}>{option}</label>
                    </div>
                  ))}
                </div>
              </div>

              <div className='form-section'>
                <label style={{ fontWeight: 'bold', fontSize: '18px' }}></label>
                <div className='checkbox-group'>
                  {antecedentOptions.map((option) => (
                      <label ></label>
                  ))}
                </div>
              </div>
              <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
              <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
            </>
          )}
          {step === 3 && (
            <>
                  <div 
            style={{
              cursor: 'pointer', 
              border: selectedImage === 'bassin' ? '2px solid blue' : '2px solid transparent', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              margin: '0 auto',
            }} 
            onClick={() => handleImageSelection('bassin')}
          >
            <img src='/images/Gray241.png' alt='Bassin de face' style={{ width: '150px', height: '150px' }} />
            <p>Anneau Pelvien</p>
          </div>

          <div 
            style={{
              cursor: 'pointer', 
              border: selectedImage === 'acetabulum' ? '2px solid blue' : '2px solid transparent', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              margin: '0 auto',
            }} 
            onClick={() => handleImageSelection('acetabulum')}
          >
            <img src='/images/Acetabulum.gif' alt='Acétabulum de profil' style={{ width: '150px', height: '150px' }} />
            <p>Acétabulum</p>
          </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
                  <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
                </div>
            </>
          )}

          {step === 4 && selectedImage === 'bassin' &&(
            <>
            <div 
              style={{
                cursor: 'pointer', 
                border: selectedSousImage === 'AO' ? '2px solid blue' : '2px solid transparent', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                margin: '0 auto',
              }} 
              onClick={() => handleSousImageSelection('AO')}
            >
            <img 
              src="/images/AO_TILE.svg" 
              alt="Bassin de face" 
              style={{ width: '150px', height: '150px' }} 
            />
            <p>AO/ Tile Classification</p>
          </div>

          <div 
            style={{
              cursor: 'pointer', 
              border: selectedSousImage === 'fragile' ? '2px solid blue' : '2px solid transparent', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              margin: '0 auto',
            }} 
            onClick={() => handleSousImageSelection('fragile')}
          >
            <img src='/images/FFP.svg' alt='Acétabulum de profil' style={{ width: '150px', height: '150px' }} />
            <p>Fracture de Fragilité</p>
          </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
                  <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
                </div>
            </>
          )}

          {step === 4 && selectedImage === 'acetabulum' &&(
            <>
                  <div 
            style={{
              cursor: 'pointer', 
              border: selectedSousImage === 'tournelle' ? '2px solid blue' : '2px solid transparent', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              margin: '0 auto',
            }} 
            onClick={() => handleSousImageSelection('tournelle')}
          >
            <img src='/images/Letournel.svg' alt='Bassin de face' style={{ width: '250px', height: '150px' }} />
            <p>Judet Letournel</p>
          </div>

          <div 
            style={{
              cursor: 'pointer', 
              border: selectedSousImage === 'autres' ? '2px solid blue' : '2px solid transparent', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              margin: '0 auto',
            }} 
            onClick={() => handleSousImageSelection('autres')}
          >
            <img src='/images/other.svg' alt='Acétabulum de profil' style={{ width: '250px', height: '150px' }} />
            <p>Autres</p>
          </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
                  <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
                </div>
            </>
          )}

          {step === 5 && (
  <>
    {selectedImage && (
      <>
        {photos.map((photo, index) => (
          <React.Fragment key={index}>

            {(index ) === 0 && selectedSousImage === 'AO' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Lésions Stables
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 3 && selectedSousImage === 'AO' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Instabilité horizontale
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 6 && selectedSousImage === 'AO' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Instabilité verticale
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 0 && selectedSousImage === 'tournelle' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Fractures Symples
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 5 && selectedSousImage === 'tournelle' && (
              <>
                <div></div>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Fractures Complexes
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 0 && selectedSousImage === 'autres' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    Autres
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 0 && selectedSousImage === 'fragile' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    FFP1
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 3 && selectedSousImage === 'fragile' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    FFP2
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 6 && selectedSousImage === 'fragile' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    FFP3
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            {(index ) === 9 && selectedSousImage === 'fragile' && (
              <>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                <h3 style={{ 
                  textAlign: 'center', 
                  marginBottom: '0.5rem', 
                  width: '100%', 
                  color: '#A9A9A9', // Darker grey color
                  fontWeight: 'bold', 
                  textTransform: 'uppercase'
                }}>
                    FFP4
                </h3>
                <hr style={{ width: '100%', borderColor: '#D3D3D3' }} />
                {/* <hr style={{ width: '100%', margin: '0.1rem auto', borderColor: 'grey' }} /> */}
              </>
            )}
            <div 
              style={{ 
                textAlign: 'center', 
                border: selectedPhotos.includes(photo) ? '2px solid blue' : '2px solid transparent',
                marginBottom: '1rem',
              }}
            >
              
              <img
                src={photo}
                alt={`Photo ${index + 1}`}
                style={{
                  width: '250px',
                  height: '250px',
                  margin: '0.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => togglePhotoSelection(photo)}
              />
              <p style={{ fontWeight: 'bold', fontSize: '14px' }}>
                {photoDescriptions[index]}
              </p>
            </div>


          </React.Fragment>
        ))}
      </>
    )}

    <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
      <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
      <button type='button' className='btn btn-block' onClick={handleSubmit}>Suivant</button>
    </div>
    {/* <div><p style={{textAlign: 'center', fontSize: '10px' }}>©AO Foundation</p></div> */}
    <div>
      <p style={{ textAlign: 'center', fontSize: '10px' }}>
        {selectedSousImage === 'AO' && '©AO Foundation'}
        {selectedSousImage === 'tournelle' && '©AO Foundation'}
        {selectedSousImage === 'autres' && ''}
        {selectedSousImage === 'fragile' && '©Journal of Bone and Joint Surgery'}
        {!['AO', 'tournelle', 'autres', 'fragile'].includes(selectedSousImage) && '©Default Text'}
      </p>
    </div>

    <div></div>
    <div style={{width:'100%'}}>
        {showAlert && <Alert />}
    </div>
  </>
)}

          {step === 6 && (
            <>
              <div className='form-section'>
                <label style={{ fontWeight: 'bold', fontSize: '18px' }}>Sélectionnez le traitement choisis.</label>
                <div className='checkbox-group'>
                  {antecedentOptions.map((option) => (
                      <label ></label>
                  ))}
                </div>
              </div>

              <div className='form-section'>
                {/* <label style={{ fontWeight: 'bold', fontSize: '18px' }}>Traitements</label> */}
                <div className='checkbox-group'>
                  {traitementOptions.map((option) => (
                    <div className='checkbox-item' key={option}>
                      <input
                        type='checkbox'
                        id={option}
                        name='traitement'
                        value={option}
                        checked={traitement.includes(option)}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={option}>{option}</label>
                    </div>
                  ))}
                </div>
              </div>

                {/* <label style={{ fontWeight: 'bold', fontSize: '18px' }}></label> */}
              {/* <div>
                <button type='button' className='btn btn-block clear-btn' onClick={() => setStep(step - 1)}>Retour</button>
                <button type='submit' className='btn btn-block' onClick={handleSubmit}>Submit</button>
              </div> */}
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', marginTop: '20px' }}>
                <button
                  type="button"
                  className="btn btn-block clear-btn"
                  style={{
                    flex: 1,
                    textAlign: 'center',
                  }}
                  onClick={() => setStep(step - 1)}
                >
                  Retour
                </button>
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{
                    flex: 1,
                    textAlign: 'center',
                  }}
                  onClick={handleSubmit}
                >
                  Soumettre
                </button>
              </div>

              <div></div>

                            <Card isVisible={isModalVisible} onDismiss={handleDismiss} />

            </>
          )}

        </div>
      </form>
    </Wrapper>
  );
};

export default AddAccident;
