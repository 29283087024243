import { useState } from 'react'
import { FormRow, Alert } from '../../components'
import { useAppContext } from '../../context/appContext'
import Wrapper from '../../assets/wrappers/DashboardFormPage'
const Profile = () => {
  const { user, showAlert, displayAlert, updateUser, isLoading } =
    useAppContext()

  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [lastName, setLastName] = useState(user?.lastName)
  const [location, setLocation] = useState(user?.location)
  const [hospital, setHospital] = useState(user?.hospital)
  const [password, setPassword] = useState(user?.password)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || !email || !lastName || !location || !hospital) {
      displayAlert()
      return
    }
    updateUser({ name, email, lastName, location, hospital, password })
  }

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <h3>profile</h3>
        {showAlert && <Alert />}
        <div className='form-center'>
          <FormRow
            type='text'
            labelText='Prénom'
            name='name'
            value={name}
            handleChange={(e) => setName(e.target.value)}
          />
          <FormRow
            type='text'
            labelText='Nom'
            name='lastName'
            value={lastName}
            handleChange={(e) => setLastName(e.target.value)}
          />
          <FormRow
            type='email'
            name='email'
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
          />
          <FormRow
            type='text'
            name='Ville'
            value={location}
            handleChange={(e) => setLocation(e.target.value)}
          />
          <FormRow
            type='text'
            name='Institution'
            value={hospital}
            handleChange={(e) => setHospital(e.target.value)}
          />
          <FormRow
            type='text'
            name='nouveau mot de passe'
            value={password}
            handleChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ height: '55px' }}></div>

          <button className='btn btn-block' type='submit' disabled={isLoading}>
            {isLoading ? 'Veuillez patienter...' : 'Enregistrer modifications'}
          </button>
        </div>
      </form>
    </Wrapper>
  )
}

export default Profile
