import { useAppContext } from '../context/appContext';
import { Navigate, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';

const ProtectedRoute = ({ children }) => {
  const { user, userLoading } = useAppContext();
  const location = useLocation();  // Get the current location

  if (userLoading) return <Loading />;

  if (!user) {
    return <Navigate to='/landing' />;
  }
  if (!user.isProfileComplete && location.pathname !== '/complete-profile') {
    return <Navigate to='/complete-profile' />;
  }
  return children;
};

export default ProtectedRoute;
