import { useState, useEffect } from 'react'; 
import { Logo, FormRow, Alert } from '../components';
import Wrapper from '../assets/wrappers/RegisterPage';
import { useAppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
  email: '',
  name: '',
  lastName:'',
  location:'',
  hospital:''
};

const Demande = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { user, isLoading, showAlert, displayAlert, requestAccount } = useAppContext();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, name, lastName, location, hospital } = values;

    if (!email || !name || !lastName || !location || !hospital) {
      displayAlert('Veuillez remplir tous les champs.');
      return;
    }

    try {
      await requestAccount({ email, name, lastName, location, hospital });
      setTimeout(() => {
        navigate('/');
      }, 5000);
    } catch (error) {
      console.error("Account request failed", error);
    }
  };


  return (
    <Wrapper className="full-page">
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>Demander un Compte</h3>
        {showAlert && <Alert />}

        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />
        <FormRow
          type="text"
          name="name"
          labelText="Prénom"
          value={values.name}
          handleChange={handleChange}
        />
        <FormRow
          type="text"
          name="lastName"
          labelText="Nom"
          value={values.lastName}
          handleChange={handleChange}
        />
        <FormRow
          type="text"
          name="location"          
          labelText="Ville"
          value={values.location}
          handleChange={handleChange}
        />        
        <FormRow
          type="text"
          name="hospital"
          labelText="Hopital"
          value={values.hospital}
          handleChange={handleChange}
        />

        <button type="submit" className="btn btn-block" disabled={isLoading}>
          Envoyer
        </button>
      </form>
    </Wrapper>
  );
};

export default Demande;