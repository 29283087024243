import React, { useState } from 'react';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import Aile_iliaque from '../../assets/images/Aile_iliaque.png';
import Bord_posterieur from '../../assets/images/Bord_posterieur.png';
import Cadre_obscurateur from '../../assets/images/Cadre_obscurateur.png';
import Paroi_posterieur from '../../assets/images/Paroi_posterieur.png';
import Sacroiliaque from '../../assets/images/Sacroiliaque.png';



const Diagnostic = () => {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({});

  const handleAnswer = (step, answer) => {
    setAnswers({ ...answers, [step]: answer });
    setStep(step + 1);
  };

  const handleRetakeQuiz = () => {
    setStep(1);
    setAnswers({});
  };
  
  const handleBack = () => {
    setStep(step - 1);
  };

  const handleRefresh = () => {
  window.location.reload();
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <>
            <h2>Aile Iliaque</h2>
            <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
            </p>
            <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                    <button className="btn btn-danger" onClick={handleRefresh} style={{ width:'80%' }}>Actualiser</button> 
                </div>            
              <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/Iliac_fossa_04_animation.gif/330px-Iliac_fossa_04_animation.gif" 
                  src={Aile_iliaque} 
                  alt="Aile Iliaque" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(1, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(1, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
            <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>
            </div>
            {/* <p style={{textAlign: 'center', fontSize: '14px' }}>©Groupe Hospitalier Paris Saint Joseph</p> */}
          </>
        );
      case 2:
        if (answers[1] === 'yes') {
          return (
            <>
              <h2>Bord Postérieur</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/PSIS_-_animation05_%28Right_hip_bone%29.gif/330px-PSIS_-_animation05_%28Right_hip_bone%29.gif" 
                  src={Bord_posterieur}
                  alt="Bord Postérieur" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(2, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(2, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
                <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>
            </div>
            </>
          );
        } else {
          return (
            <>
              <h2>Bord Postérieur</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f2/PSIS_-_animation05_%28Right_hip_bone%29.gif/330px-PSIS_-_animation05_%28Right_hip_bone%29.gif" 
                  src={Bord_posterieur}
                  alt="Bord Postérieur" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(2, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(2, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>
            </div>
            </>
          );
        }
      case 3:
      if (answers[1] === 'yes' && answers[2] === 'no' ) {
        return (
          <>
            <h2>Résultat</h2>
            <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Colonne Antérieure</h3>
            {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
            <div className="form-center">
              <div></div>
              <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>
                Repasser Quiz 
              </button>
            </div>
          </>
        );
      } 
      else if (answers[1] === 'yes' && answers[2] === 'yes' ) {
        return (
          <>
              <h2>Sacroiliaque</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/0/0d/Auricular_surface_of_ilium_-_animation02.gif?20150219110018" 
                  src={Sacroiliaque}
                  alt="Sacroiliaque" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(3, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(3, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
          </>
        );
      }
      case 4:
        if (answers[1] === 'yes' && answers[2] === 'yes' && answers[3] === 'yes') {
          return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Colonne antérieure + hémitransversale postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
        }
        else if (answers[1] === 'yes' && answers[2] === 'yes' && answers[3] === 'no') {
          return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Deux Colonnes</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
        }
      case 5:
      if (answers[1] === 'no' && answers[2] === 'yes' ) {
        return (
            <>
              <h2>Cadre Obturateur</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/0/0f/Obturator_foramen_02_animation.gif" 
                  src={Cadre_obscurateur}
                  alt="Cadre Obturateur" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(5, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(5, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'no' ) {
        return (
            <>
              <h2>Paroi Postérieure</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/8/82/Ischium_02_animation.gif" 
                  src={Paroi_posterieur}
                  alt="Paroi Postérieure" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(5, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(5, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
            </>
          );
      }
      case 6:
      if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes') {
        return (
            <>
              <h2>Détroit Supérieur</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/3/3a/Arcuate_line_of_ilium_04_animation_%28Right_hip_bone%29.gif" 
                  src={Cadre_obscurateur}
                  alt="Cadre Obturateur" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(6, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(6, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'no' ) {
        return (
            <>
              <h2>Paroi Postérieure</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>            
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/8/82/Ischium_02_animation.gif" 
                  src={Paroi_posterieur}
                  alt="Paroi Postérieure" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(6, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(6, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
            </>
          );
      }
      case 7:
      if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[6] === 'yes') {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Fracture en T</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[6] === 'no' ) {
          return (
            <>
              <h2>Paroi Postérieure</h2>
              <p style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center', maxWidth:'none' }}>
                Y a-t-il une ligne de fracture impliquant la zone surlignée ?
              </p>
              <div className="form-center">
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                  <button className="btn clear-btn" onClick={handleBack} style={{ width:'80%' }}>Retour</button> 
                </div>
                <div>
                <img 
                  // src="https://upload.wikimedia.org/wikipedia/commons/8/82/Ischium_02_animation.gif" 
                  src={Paroi_posterieur}
                  alt="Paroi Postérieure" 
                  style={{ marginLeft: '5rem', width: '150px', height: '150px', marginTop: '1rem' }} 
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(7, 'yes')} 
                  style={{ width:'45%' }}
                >
                  Oui
                </button>
                <button 
                  type="button" 
                  className="btn" 
                  onClick={() => handleAnswer(7, 'no')} 
                  style={{ width:'45%' }}
                >
                  Non
                </button>
              </div>
              <div></div>
              <p style={{textAlign: 'center', fontSize: '10px' }}>©Groupe Hospitalier Paris Saint Joseph</p>
              <div></div>              
            </div>
            </>
          );
      }
      
      case 8:
      if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[5] === 'no') {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Transversale</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[6] === 'yes' ) {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Transversale + Paroi Postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      }

      case 9:
      if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[6] === 'no' && answers[7] === 'yes' ) {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Colonne Postérieure + Paroi Postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'yes' && answers[6] === 'no' && answers[7] === 'no') {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Colonne Postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      }

      case 10:
      if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'no' && answers[6] === 'yes' ) {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Transversale + Paroi Postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'yes' && answers[5] === 'no' && answers[6] === 'no') {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Transversale</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      }

      case 11:
      if (answers[1] === 'no' && answers[2] === 'no' && answers[5] === 'no' ) {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Paroi Antérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      } else if (answers[1] === 'no' && answers[2] === 'no' && answers[5] === 'yes') {
        return (
            <>
              <h2>Résultat</h2>
              <h3 style={{ fontWeight: 'bold', textAlign: 'center', maxWidth:'none' }}>Paroi Postérieure</h3>
              {/* <img src="full_protection_image_url" alt="Fully Protected" style={{ width: '150px', height: '150px', marginTop: '1rem' }} /> */}
              <div className="form-center">
                <div></div>
                <button className="btn" onClick={handleRetakeQuiz} style={{ display: 'grid', placeItems: 'center', marginTop: '1rem' }}>Retake Quiz</button>
              </div>
            </>
          );
      }

      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <form className="form" onSubmit={(e) => e.preventDefault()}>
        {/* <h3>Diagnostic</h3> */}
        {renderStep()}
      </form>
    </Wrapper>
  );
}

export default Diagnostic;
