import React, { useEffect } from 'react';
import { useAppContext } from '../../context/appContext';
import Wrapper from '../../assets/wrappers/DashboardFormPage';
import styled from 'styled-components';
import { Alert } from '../../components';

const Wrapper2 = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
  }

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  /* Adjust table for smaller screens */
  @media (max-width: 768px) {
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }

    th, td {
      font-size: 0.8rem;
      padding: 8px;
    }
  }

  @media (max-width: 480px) {
    th, td {
      font-size: 0.75rem;
      padding: 5px;
    }
  }
`;


const AllUsers = () => {
  const { getAllUsers, users = [], isLoading, showAlert, error } = useAppContext();

  // Fetch users if not loaded yet
  useEffect(() => {
    if (!isLoading && users.length === 0) {
      getAllUsers();  // Trigger API call to get users
    }
    console.log('Users state after fetching:', users); // Verify the state
  }, [isLoading, users.length, getAllUsers]);


  // If still loading, show a loading message or spinner
  if (isLoading) {
    return <p>Chargement des utilisateurs...</p>;
  }

  // Handle errors if any
  if (error) {
    return <Alert message={error} />;
  }

  // Once loading is done, render the table with users
  return (
    <Wrapper>
      <Wrapper2>
        <h3>Tous les utilisateurs</h3>
        {showAlert && <Alert />}
        <table>
          <thead>
            <tr>
              <th>Numéro</th>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Ville</th>
              <th>Hopital</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ? (
              users.map((user,index) => (
                <tr key={user._id}>
                  <td>{index+1}</td>
                  <td>{user.name}</td>
                  <td>{user.lastName}</td>
                  <td>{user.email}</td>
                  <td>{user.location}</td>
                  <td>{user.hospital}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">Aucun utilisateur trouvé</td>
              </tr>
            )}
          </tbody>
        </table>
      </Wrapper2>
    </Wrapper>
  );
};


export default AllUsers;
