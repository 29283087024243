import { useState, useEffect } from 'react'; 
import { Logo, FormRow, Alert } from '../components';
import Wrapper from '../assets/wrappers/RegisterPage';
import { useAppContext } from '../context/appContext';
import { useNavigate } from 'react-router-dom';

const initialState = {
  name: '',
  email: '',
  password: '',
  isMember: true,
  isForgotPassword: false,
};

const Register = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState(initialState);
  const { user, isLoading, showAlert, displayAlert, setupUser, resetPassword } =
    useAppContext();

  const toggleMember = () => {
    setValues({ ...values, isMember: !values.isMember });
  };

  const toggleForgotPassword = () => {
    setValues({ ...values, isForgotPassword: !values.isForgotPassword });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, isMember, isForgotPassword } = values;

if (isForgotPassword) {
  if (!email) {
    displayAlert('Veuillez fournir un email.');
    return;
  }
    resetPassword({ email });
  return;
}


    if (!email || !password || (!isMember && !name)) {
      displayAlert('Veuillez remplir tous les champs.');
      return;
    }

    const currentUser = { name, email, password };
    if (isMember) {
      setupUser({
        currentUser,
        endPoint: 'login',
        alertText: 'Connexion réussie! Redirection en cours...',
      });
    } else {
      setupUser({
        currentUser,
        endPoint: 'register',
        alertText: 'Utilisateur créé ! Redirection en cours...',
      });
    }
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }
  }, [user, navigate]);

  return (
    <Wrapper className="full-page">
      <form className="form" onSubmit={onSubmit}>
        <Logo />
        <h3>
          {values.isForgotPassword
            ? 'Mot de passe oublié'
            : values.isMember
            ? 'Connexion'
            : 'Créer un compte'}
        </h3>
        {showAlert && <Alert />}

        {!values.isForgotPassword && !values.isMember && (
          <FormRow
            type="text"
            name="name"
            value={values.name}
            handleChange={handleChange}
          />
        )}

        <FormRow
          type="email"
          name="email"
          value={values.email}
          handleChange={handleChange}
        />
        {!values.isForgotPassword && (
          <FormRow
            type="password"
            name="password"
            labelText="Mot de passe"
            value={values.password}
            handleChange={handleChange}
          />
        )}

        <button type="submit" className="btn btn-block" disabled={isLoading}>
          {values.isForgotPassword ? 'Envoyer' : 'Soumettre'}
        </button>

        {values.isForgotPassword ? (
          <p>
            Vous vous souvenez de votre mot de passe ?{' '}
            <button
              type="button"
              onClick={toggleForgotPassword}
              className="member-btn"
            >
              Connexion
            </button>
          </p>
        ) : (
          <p>
            <button
              type="button"
              onClick={toggleForgotPassword}
              className="member-btn"
            >
              {values.isForgotPassword ? 'Connexion' : 'Mot de passe oublié ?'}
            </button>
          </p>
        )}
      </form>
    </Wrapper>
  );
};

export default Register;