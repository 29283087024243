import { IoBarChartSharp } from 'react-icons/io5'
import { MdQueryStats } from 'react-icons/md'
import { FaPlus, FaResearchgate, FaSearch, FaSearchDollar, FaSearchengin, FaUser, FaUserCog, FaUserFriends, FaUserPlus, FaUsers, FaWpforms } from 'react-icons/fa'
import { ImProfile } from 'react-icons/im'

const links = [

  { id: 1, text: 'ajouter accident', path: '/', icon: <FaWpforms /> },
  { id: 4, text: 'aide au diagnostic', path: 'diagnostic', icon: <FaSearch /> },
  { id: 5, text: 'profil', path: 'profile', icon: <ImProfile /> },
  { id: 3, text: 'stats', path: 'stats', icon: <IoBarChartSharp /> },
  { id: 6, text: 'ajouter utilisateurs', path: 'add-user', icon: <FaPlus /> },
  { id: 7, text: 'utilisateurs', path: 'users', icon: <FaUsers /> },



]

export default links
