import { useState, useEffect } from 'react';
import { FormRow, Alert } from '../components';
import { useAppContext } from '../context/appContext';
import Wrapper from '../assets/wrappers/DashboardFormPage';
import { useNavigate } from 'react-router-dom';


const CompleteProfile = () => {
  const { user, showAlert, displayAlert, updateUser, isLoading } = useAppContext();

  const [name, setName] = useState(user?.name || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email)
  const [location, setLocation] = useState(user?.location || '');
  const [hospital, setHospital] = useState(user?.hospital || '');

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!name || !lastName || !location || !hospital) {
      displayAlert();
      return;
    }

    // Update the user information and mark profile as complete
    updateUser({
      name,
      lastName,
      location,
      hospital,
      email,
      isProfileComplete: true,
    });
 };
 useEffect(() => {
  if (user?.isProfileComplete) {
    navigate('/');
  }
}, [user?.isProfileComplete, navigate]); 

  return (
    <Wrapper>
      <form className="form" onSubmit={handleSubmit}>
        <h3>Complete Profile</h3>
        {showAlert && <Alert />}
        <div className="form-center">
          <FormRow
            type="text"
            labelText="Prénom"
            name="name"
            value={name}
            handleChange={(e) => setName(e.target.value)}
          />
          <FormRow
            type="text"
            labelText="Nom"
            name="lastName"
            value={lastName}
            handleChange={(e) => setLastName(e.target.value)}
          />
          <FormRow
            type='email'
            name='email'
            value={email}
            handleChange={(e) => setEmail(e.target.value)}
          />
          <FormRow
            type="text"
            labelText="Ville"
            name="location"
            value={location}
            handleChange={(e) => setLocation(e.target.value)}
          />
          <FormRow
            type="text"
            labelText="Institution"
            name="hospital"
            value={hospital}
            handleChange={(e) => setHospital(e.target.value)}
          />
          <button className="btn btn-block" type="submit" disabled={isLoading}>
            {isLoading ? 'Veuillez patienter...' : 'Enregistrer modifications'}
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default CompleteProfile;
